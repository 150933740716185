import { useReCaptcha } from 'vue-recaptcha-v3'

export class RecaptchaAction {
  public static readonly login = new RecaptchaAction('login')
  public static readonly social = new RecaptchaAction('social')

  private constructor(public readonly name: string) {}
}

/**
 * The exported executeRecaptcha function allows
 * you to execute reCAPTCHA actions
 * and retrieve the reCAPTCHA token along with the header options
 * to be used in subsequent requests.
 */
export default () => {
  const recaptchaInstance = useReCaptcha()

  const executeRecaptcha = async (action: RecaptchaAction) => {
    /**
     * Wait for the recaptchaInstance to be loaded
     * by calling the recaptchaLoaded method.
     * This ensures that the reCAPTCHA library is fully loaded
     * and ready to execute reCAPTCHA actions.
     */
    await recaptchaInstance?.recaptchaLoaded()
    return await recaptchaInstance?.executeRecaptcha(action.name)
  }

  return { executeRecaptcha }
}
